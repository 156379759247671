.addressInput input {
  text-transform: capitalize;
}
.fullscreenload {
  font-size: 40px;
  color: #fff;
}
.shopping-summery table thead th {
  background-color: #f4f6fa !important;
}
.old-price {
  color: #000 !important;
}
.cutsom-phone-input {
  border: 1px solid #ececec;
  border-radius: 10px;
  height: 47px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 16px;
  width: 47%;
  margin: 0 auto;
}
.cutsom-phone-input span {
  font-size: 16px !important;
}
.cutsom-phone-input input {
  border: none;
  height: 45px;
  padding-left: 0;
}
.btn:hover,
.button:hover {
  background-color: var(--primary-colors) !important;
  color: #fff !important;
  border: 1px solid var(--primary-colors) !important;
}
.primary-color {
  color: var(--primary-colors) !important;
}
.secondary-color {
  color: var(--secondary-color) !important;
}
.bg-secondary-color {
  background-color: var(--secondary-color) !important;
}
a:hover {
  color: var(--secondary-color) !important;
}
.font-normal {
  font-size: 14px !important;
}
.font-mainHead {
  font-size: 18px !important;
}
.font-head {
  font-size: 16.5px !important;
}
.font-small {
  font-size: 13px !important;
}
.font-smaller {
  font-size: 12px !important;
}
.font-bigger {
  font-size: 28px !important;
}

.carausel-10-columns-cover .carausel-10-columns {
  margin: 0 !important;
}

.product-cart-wrap.style-2 .produt-img-action-wrap img,
.hero-slider-1 .single-hero-slider,
.background_image,
.product-cart-wrap .product-img-action-wrap .product-img,
.product-cart-wrap,
.custom_footer,
.newsletter .newsletter-inner,
.banner-left-icon {
  border-radius: 5px !important;
}
.prd_vertical h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.prd_vertical h2 a {
  color: #253d4e;
}
.product-cart-wrap .product-img-action-wrap .product-img.newCollBanner {
  border-radius: 0 !important;
}
.border-radious-0 {
  border-radius: 0 !important;
}
.border-radious-small {
  border-radius: 5px;
}
.max-h-360 {
  max-height: 360px;
}
.custo_cat_slider {
  min-height: 165px;
}
.titles {
  position: absolute;
  top: 0;
  padding: 2rem;
}
.bannerFooterImg {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.bulkloading {
  max-height: 400px;
  max-width: 400px;
}
.background_image {
  position: relative;
  cursor: pointer;
}
.product-cart-wrap .product-img-action-wrap .product-img a img.hover-img {
  height: 100%;
}
.card-2 figure img {
  max-width: 70px;
}
.onlyOneBanner ~ .slider-arrow .custom_prev_i1,
.onlyOneBanner ~ .slider-arrow .custom_next_i1 {
  display: none !important;
}
.fw-400 {
  font-weight: 400;
}
.mobileContainer {
  height: 260px;
}
.mobileCat img {
  height: 150px;
  width: 150px;
  object-fit: contain;
  aspect-ratio: 2/2;
  /* mix-blend-mode: color-burn; */
}
.removeFromCart {
  position: absolute;
  background-color: #ffffffd9;
  border-radius: 50%;
  color: #ee485ed4;
}
.list-filter li:hover a {
  color: #fff !important;
}
.product-thumbnail a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.background_image {
  padding: 0 !important;
}
.background_image img {
  max-height: 550px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.product-cart-wrap .product-img-action-wrap {
  padding: 0;
}
.product-cart-wrap .product-content-wrap {
  padding: 12px 12px 12px 12px;
  /* min-height: 97px; */
}
.product-cart-wrap .product-img-action-wrap .product-img {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.MobileNav {
  display: none;
}
.modal-body .deal {
  border-radius: 15px;
}
.cart_image {
  width: 120px;
}
.custom-modal .modal-dialog .modal-content {
  border-radius: 15px;
}
.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button {
  justify-content: end;
}
.product-cart-wrap .product-card-bottom .add-cart .add {
  /* background-color: #fdd2d9; */
  background-color: #22b3bf;
  color: #fff;
}
.product-grid-3 {
  margin-top: 20px;
}
.product-cart-wrap .product-card-bottom .add-cart .add:hover {
  color: #fff !important;
}
.header-style-1 .search-style-2 form {
  border: 2px solid var(--secondary-color);
}
.mySearchPage form input {
  border-radius: 0;
  border: 0;
}
.mySearchPage form input:focus {
  border: none;
}
.search_page_wrap {
  background-color: #f1f3f6;
  height: 100vh;
}
.search_page_wrap .myMobileSearchList {
  top: 52px;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
}
.myMobileSearchList .product-list-small h6 {
  font-weight: normal !important;
  font-size: 13px;
}
.myMobileSearchList .product-list-small .product-price {
  margin-top: 0;
  font-size: 14px;
}

.myMobileSearchList .product-list-small figure {
  margin-bottom: 0 !important;
}
.myMobileSearchList .animate__animated {
  border-bottom: 1.5px solid #f1f3f6;
  margin-bottom: 0;
  padding-bottom: 8px;
}
.search_page_wrap .myMobileSearchList img {
  height: 40px !important;
  width: 40px !important;
  border-radius: 0;
}
.mySearchPage {
  background-color: #fff;
}

.header-action-icon {
  padding: 0 10px;
}
.cart-dropdown-wrap {
  width: 400px;
}
.myBorderTop {
  border-top: "1px solid #c6c6c7" !important;
}
.navIcon2 {
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -9px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infinite-scroll-component {
  /* overflow: inherit !important; */
  overflow: unset !important;
}
.color-darkGreen {
  color: var(--primary-colors) !important;
}
.bg-darkGreen {
  background-color: var(--primary-colors) !important;
}
.active_tag {
  background-color: #d0ecef !important;
  /* background-color: #c5eada !important; */
  color: #000 !important;
}
.active_tag a {
  color: #000 !important;
}

.collection_swipe {
  width: 400px;
  height: 200px;
  position: relative;
}
.collection_swipe a {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 99;
  font-size: 16px;
}
.collection_swipe img {
  height: 165px;
  border-radius: 10px;
}

.custom_category_label {
  border: none !important;
  box-shadow: none !important;
}
.custom_category_label ul {
  display: flex;
}

.custom_swipe .swiper-wrapper {
  display: flex !important;
  justify-content: center !important;
}
.custom_swipe .swiper-wrapper .swiper-slide {
  margin-right: 0 !important;
  margin-left: 10px;
  max-width: 140px;
}
.custom_swipe .swiper-wrapper .swiper-slide:first-child {
  margin: 0 !important;
}
.product-image-slider {
  border: none !important;
}

.custom_footer {
  background: #f4f6fa;
  padding: 2rem;
  border-radius: 15px;
}
.categories-dropdown-active-large {
  min-width: 600px;
}
.bg-yellow {
  background-color: #f5d646 !important;
}
.bg-yellow-light {
  background-color: #fceca6 !important;
  color: #2e9d5e;
}
.text-green {
  color: #29a56c !important;
}
.bg-green {
  background-color: #29a56c !important;
}
.bg-green:hover {
  background-color: #188246 !important;
  color: #ffff !important;
}
.underline {
  text-decoration: underline;
}
.moreProdu {
  max-width: 45%;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.myButton {
  background-color: #2e9d5e !important;
}
.myButton:hover {
  background-color: #1c7141 !important;
}

.header-action-icon-2 {
  height: 25px !important;
}
.scroll-300 {
  max-height: 300px;
  overflow-y: scroll;
}
.white-space-nowrap {
  white-space: nowrap;
}
.opacity-half {
  opacity: 0.5;
}

.break {
  word-break: break-all !important;
}
.curvy {
  border-radius: 5px 0px 5px 0px !important;
}
.rounded-xs {
  border-radius: 10px !important;
}
.rounded-sm {
  border-radius: 5px !important;
}
.rounded-md {
  border-radius: 20px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-notAllowed {
  cursor: not-allowed !important;
}

/* //hieght */
.h\:80px {
  height: 80px !important;
}
.w\:80px {
  width: 80px !important;
}
.h-fit {
  height: fit-content;
}
.h\:40px {
  height: 40px !important;
}
.h\:100px {
  height: 100px;
}
.h\:130px {
  height: 130px;
}
.h\:150px {
  height: 150px !important;
}
.h\:200px {
  height: 200px !important;
}
.h\:160px {
  height: 160px !important;
}
.h\:300px {
  height: 300px !important;
}
.h\:250px {
  height: 250px !important;
}
.h-95 {
  height: 95% !important;
}
.h-90 {
  height: 90% !important;
}
.h\:50px {
  height: 50px !important;
}
.h-half {
  height: 60vh !important;
}
.max-h\:200px {
  max-height: 200px !important;
}
.max-h\:300px {
  max-height: 300px !important;
}
.max-h\:400px {
  max-height: 400px !important;
}
.min-h-none {
  min-height: none !important;
}
.min-h-auto {
  min-height: auto !important;
}
.min-h-100px {
  min-height: 100px !important;
}
.min-h-260px {
  min-height: 260px !important;
}
.min-h-320px {
  min-height: 320px !important;
}

/* width */
.min-w\:40px {
  min-width: 40px;
}
.min-w\:80px {
  min-width: 80px;
}
.min-w\:180px {
  min-width: 180px;
}
.min-w\:150px {
  min-width: 150px;
}
.min-w\:100px {
  min-width: 100px;
}
.max-w\:700px {
  max-width: 700px;
}
.max-w\:600px {
  max-width: 600px;
}
.max-w\:200px {
  max-width: 200px;
}
.max-w\:250px {
  max-width: 250px;
}
.max-w\:300px {
  max-width: 325px !important;
}
.max-w\:400px {
  max-width: 400px;
}
.max-w\:500px {
  max-width: 500px;
}
.w\:100px {
  width: 100px;
}

.w\:150px {
  width: 150px !important;
}

.w-fit {
  width: fit-content !important;
}
.w\:50px {
  width: 50px !important;
}

.w-70\% {
  width: 70% !important;
}

.w-90\% {
  width: 90% !important;
}

.w-20\% {
  width: 20% !important;
}
.w-50\% {
  width: 50% !important;
}
.min-w-none {
  min-width: none !important;
}

/* //border */
.border-none {
  border: none !important;
}

.border-b-4 {
  border-bottom: 1px solid #e9ecef !important;
}
.border-1-gray {
  border: 1px solid #ececec;
}

/* padding */
.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-6 {
  padding: 6rem !important;
}
.p-2 {
  padding: 2rem !important;
}
.p-1 {
  padding: 1rem !important;
}
.p-1\.5 {
  padding: 1.5rem !important;
}
/* margin */
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-5px {
  margin-right: 5px !important;
}

.ml-auto {
  margin-left: auto !important;
}
.ml-1\.5rem {
  margin-left: 1.5rem !important;
}
.ml-1rem {
  margin-left: 1rem !important;
}
.ml-0\.5rem {
  margin-left: 0.5rem !important;
}
.mx-1 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

/* //background */
.bg-transperant {
  background-color: transparent !important;
}
.bg-pista {
  background-color: #ebf7ef !important;
}
.bg-lightgreen {
  background-color: var(--primary-colors) !important;
}
.bg-black {
  background-color: #333 !important;
}
.bg-disable {
  background-color: #787878 !important;
}
.fit-cover {
  object-fit: cover !important;
}
.fit-contain {
  object-fit: contain !important;
}

/* font size */
.text-size-12 {
  font-size: 12px !important;
}

.text-size-14 {
  font-size: 14px !important;
}
.text-size-16 {
  font-size: 16px !important;
}
.text-size-20 {
  font-size: 20px !important;
}

.text-end {
  text-align: end;
}
.text-center {
  text-align: center !important;
}

.text-dthYellow {
  color: #f4d644 !important;
}
.text-green {
  color: #00c851;
}
.text-danger {
  color: #ff4444;
}
.text-info {
  color: #33b5e5;
}
.text-osperb {
  color: #17618f !important;
}
.text-default {
  color: #2bbbad;
}
.text-primary {
  color: #4285f4;
}
.text-white {
  color: #fff;
}
.text-black {
  color: #333333 !important;
}
.text-gray {
  color: #787878 !important;
}
.bg-gray {
  background-color: #787878 !important;
}
.line-h-16px {
  line-height: 16px !important;
}
.font-bold {
  font-weight: bold;
}

/* position */
.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}
.right-0 {
  right: 0;
}
.left-0 {
  left: 0;
}
.right-10 {
  right: 10px !important;
}
.right-20 {
  right: 20px !important;
}
.right-40 {
  right: 40px !important;
}
.right-50 {
  right: 50px !important;
}
.right-60 {
  right: 60px !important;
}
.right-70 {
  right: 70px !important;
}
.right-80 {
  right: 80px !important;
}

.top-1rem {
  top: 1rem !important;
}
.left-1rem {
  left: 1rem !important;
}
.top-40 {
  top: 40% !important;
}

.bottom-0 {
  bottom: 0 !important;
}
.bottom-40 {
  bottom: 40px !important;
}
.bottom-45 {
  bottom: 45px !important;
}
.bottom-50 {
  bottom: 50px !important;
}
/* /////// */
.custom-class .swiper-wrapper {
  height: 185px;
}

.custom-class.custom-class-ar .swiper-wrapper {
  height: 185px;
  justify-content: end;
}

.boxshadow-4 {
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
}

.zIndex-full {
  z-index: 99 !important;
}

.custom_banner {
  height: 285px;
  width: 314px;
  margin-right: 1rem;
}

.table-border-tr-none tbody tr {
  border: none !important;
}
.table-border-none tbody tr td {
  border: none !important;
}
.table-border-none tbody tr {
  border: none !important;
}
.custom_form_login {
  width: 40%;
}
#notification_modal .modal-dialog {
  max-width: 300px !important;
  width: auto !important;
  transition: all 0.3s ease-in-out;
}

#notification_modal .modal-dialog.center {
  top: 3rem !important;
}
#notification_modal .modal-dialog.center.hide {
  top: -5rem !important;
}
#notification_modal .modal-dialog.topRight {
  margin-right: 10px !important;
  top: 3rem !important;
}
#notification_modal .modal-dialog.topRight.hide {
  margin-right: -20rem !important;
  top: 3rem !important;
}
#notification_modal .modal-dialog.topLeft {
  margin-left: 10px !important;
  top: 3rem !important;
}
#notification_modal .modal-dialog.topLeft.hide {
  margin-left: -20rem !important;
  top: 3rem !important;
}
#notification_modal .modal-dialog.bottomRight {
  margin-right: 10px;
  top: 90%;
}
#notification_modal .modal-dialog.bottomRight.hide {
  margin-right: -20rem;
  top: 90%;
}
#notification_modal .modal-dialog.bottomLeft {
  margin-left: 10px;
  top: 90%;
}
#notification_modal .modal-dialog.bottomLeft.hide {
  margin-left: -20rem;
  top: 90%;
}
#notification_modal .modal-content {
  padding: 5px !important;
  border-radius: 10px !important;
  border: none !important;
}
#notification_modal .modal-body {
  padding: 0.5rem !important;
}

.wrapper_otp div {
  display: flex;
  justify-content: space-between;
}
.wrapper_otp input:last-child {
  margin-right: 0 !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner {
  animation: spin 1s infinite linear;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.custom-border {
  border-radius: 0 0 25px 25px !important;
}

.tada {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.tada:hover {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

.shake {
  animation: shake 1.5s both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0), scale(1.2);
  }

  20%,
  80% {
    transform: translate3d(0.5px, 0, 0) scale(1);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-0.5px, 0, 0) scale(1.2);
  }

  40%,
  60% {
    transform: translate3d(0.5px, 0, 0) scale(1);
  }
}

.penTag {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: -36px;
  right: 20px;
  background: #ffffff;
  padding: 10px;
  border-radius: 20px 20px 0px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.swiper-button-next,
.swiper-button-prev {
  color: #000 !important;
}
@media screen and (max-width: 768px) {
  .flex-revers-col {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 992px) {
  .md\:mb-2 {
    margin-bottom: 1rem;
  }
  .cutsom-phone-input {
    width: 97%;
  }
}

@media screen and (max-width: 820px) {
  .md\:mt-50 {
    margin-top: 50px !important;
  }
}
.modal-dialog {
  width: 80% !important;
}
@media screen and (max-width: 768px) {
  .cod-confirm-modal.modal-dialog {
    width: 90% !important;
    margin: auto;
  }
}
.background_image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.object-start {
  object-position: left;
}
.object-end {
  object-position: right;
}
.cart-bottom-noti {
  box-shadow: 0px 17px 20px 0px rgb(0 0 0 / 75%);
  transform: translateY(7rem);
  transition: all ease-in-out 0.3s;
  z-index: 100 !important;
}
.cart-bottom-noti.show {
  transform: translateY(0rem);
  transition: all ease-in-out 0.3s;
}
.cart-bottom-noti ul li .shopping-cart-title h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 9px;
}
.cart-bottom-noti li {
  height: 100% !important;
}

.cart-bottom-noti ul li .shopping-cart-title h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 0px;
  color: var(--primary-colors);
}
.swiper-wrapper {
  align-items: flex-start !important;
}
.main_image_slider {
  height: 442px !important;
  width: 442px !important;
  object-fit: contain;
  margin: auto;
}
.checkout-items-border {
  border-bottom: 1px solid #e9ecef !important;
  margin-bottom: 10px !important;
}
.line_load {
  width: 30px;
  background: #b6b6b6;
  height: 2px;
  margin-top: 5px;
  animation: width 2s ease-in-out infinite;
  transition: all 0.3s ease;
}

@keyframes width {
  0% {
    width: 0px;
  }
  50% {
    width: 20px;
  }
  100% {
    width: 30px;
  }
}
.order_limit .sort-by-product-wrap {
  display: flex !important;
}

.myMobileSearch {
  display: none;
}

.myDeal {
  display: none;
}
/* //phone */
@media screen and (min-width: 1500px) {
  .shoplist-child {
    min-height: 370px !important;
  }
}
@media screen and (min-width: 1200px) {
  .productSingleContainer {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .sm\:m-auto {
    margin: auto !important;
  }
  .cutsom-phone-input {
    width: 94%;
  }
  .checkout-btn {
    padding: 7px 70px !important;
  }
  .all_cat {
    border-radius: 10px !important;
    padding: 6px !important;
  }
  .sm\:text-center {
    text-align: center !important;
  }
  #loginform .panel-body {
    justify-content: flex-start;
    align-items: baseline;
  }
  textarea {
    height: auto !important;
  }
  .sidebar-widget {
    margin-bottom: 0 !important;
  }
  .discription_markup span p {
    font-size: 13px !important;
  }
  .tab-content.shop_info_tab {
    margin-top: 5px;
  }
  .bannerFooterImg {
    height: 180px;
  }
  .product-cart-wrap .product-img-action-wrap .product-img.newCollBanner {
    height: 220px !important;
  }
  .otp_container {
    margin-top: 10px;
  }
  .sm\:flex-column {
    flex-direction: column !important;
  }
  .locations_text {
    margin: 0 !important;
    text-align: start;
    width: 100%;
    padding-top: 15px;
  }

  .sm\:m-0 {
    margin: 0 !important;
  }
  .sm\:mt-4 {
    margin-top: 2rem !important;
  }
  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .sm\:mx-0\.5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .sm\:max-w\:160px {
    max-width: 160px !important;
    width: 45%;
  }
  .sm\:mb-2 {
    margin-bottom: 1rem !important;
  }
  .sm\:text-start {
    text-align: start !important;
  }
  .sm\:mb-30 {
    margin-bottom: 30px;
  }
  .custom_small_slider {
    flex-direction: column;
  }
  .custom_form_login {
    width: 100%;
  }
  .sm\:bg-image-none {
    background-image: none !important;
  }

  .custom_small_slider .custom_banner {
    width: 100% !important;
    height: 200px !important;
  }

  .sm\:w-100-pre {
    width: 100% !important;
  }
  .table td {
    display: table-cell !important;
    /* width: fit-content !important; */
    text-align: start !important;
  }
  .invoice .table td {
    display: table-cell !important;
    /* width: fit-content !important; */
    text-align: start !important;
  }
  .sm\:pt-4 {
    padding-top: 2rem !important;
  }
  .sm\:pb-1 {
    padding-bottom: 1rem !important;
  }
  .sm\:flex-row {
    flex-direction: row !important;
  }
  .sm\:w-100 {
    width: 100%;
  }
  .sm\:w-50 {
    width: 50% !important;
  }
  .sm\:w-49 {
    width: 49%;
  }
  .sm\:justify-content-between {
    justify-content: space-between;
  }
  .sm\:mb-10 {
    margin-bottom: 10px !important;
  }
  .sm\:mb-20 {
    margin-bottom: 20px !important;
  }
  .sm\:d-none {
    display: none;
  }
  .sm\:p-4 {
    padding: 2rem;
  }
  .section-title h3 {
    margin-bottom: 10px !important;
  }
  .section_head_h3 {
    font-size: 14px !important;
    color: #506370;
    font-weight: 400 !important;
  }
  .section-title {
    margin-bottom: 5px !important;
  }
  .home-slider {
    margin-bottom: 0px !important;
  }
  .card-2 h6 {
    font-size: 14px !important;
  }
  .header-bottom {
    padding: 5px 0;
  }
  .header-action-2 .header-action-icon-2 > a img {
    max-width: 20px;
  }
  .header-action-2 .header-action-icon-2 > a {
    margin-left: 10px;
  }

  .burger-icon {
    height: 20px;
    width: 20px;
  }
  .header-action-icon {
    padding: 0 1px;
  }
  /* .burger-icon > span.burger-icon-mid {
    top: 7.5px;
  } */
  .myMobileSearch {
    display: flex;
  }
  .myMobileSearch form input {
    height: 30px;
    padding: 5px 5px 5px 30px;
    font-size: 12px;
    background-position: 10px 9px;
    background-size: 23px 12px;
    /* background-position: center left; */
  }
  .myMobileSearch {
    padding: 0 10px;
    margin-bottom: 5px;
    margin-top: 15px;
  }
  .myMobileSearch.scroll {
    margin-top: 5px;
  }
  .header-bottom .container {
    padding-top: 5px;
  }
  .banner-left-icon .banner-text h3 {
    font-size: 16px;
  }
  .banner-left-icon .banner-text p {
    font-size: 14px;
    line-height: 14px;
  }
  .footer-mid .container {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .footer-mid .widget-title {
    font-size: 18px;
  }
  .footer-list li a {
    font-size: 14px;
  }
  .myCart .heading-2 {
    font-size: 20px;
    margin-bottom: 2px !important;
  }
  .myCart .subHead {
    font-size: 14px !important;
  }
  .myCart .col-lg-8 {
    margin-bottom: 0 !important;
  }
  .myCheckout .container,
  .myCart .container {
    padding: 10px !important;
    padding-top: 20px !important;
  }
  .myCheckout {
    margin-top: 1rem !important;
  }
  .myCheckout .heading-2 {
    font-size: 20px;
  }
  .myCheckout .panel-body h5 {
    font-size: 18px;
  }
  .myCheckout form button {
    font-size: 13px !important;
    padding: 10px 20px !important;
    width: 100%;
  }
  .mobile_text {
    text-align: center !important;
  }
  .mobileForm {
    width: 240px;
    margin: auto;
  }
  .mobileOtpinput {
    justify-content: center !important;
  }
  .myCheckout .header,
  .myCheckout .inputField {
    padding: 0 20px;
  }
  .login_form .panel-body {
    border: none;
    padding: 0;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 0 10px;
  }
  .otpButton,
  .otpButton button {
    width: 100%;
  }
  .modal-dialog.addressFrom {
    width: 90% !important;
  }
  .login_form .panel-body h6 {
    font-size: 20px;
    max-width: 160px;
    text-align: center;
    color: #335243;
  }
  .login_form .text-size-20.mr-1 {
    font-size: 16px !important;
  }
  .login_form .form-group input {
    font-size: 14px !important;
  }
  .order_details h4 {
    font-size: 18px;
  }
  .breadcrumb-wrap {
    display: none;
  }
  .shopping-summery h6 {
    font-size: 14px;
    font-weight: 500;
  }
  /* .detail-qty > a.qty-up {
    top: 5px;
    right: 9px;
    transform: rotate(90deg);
    color: black;
  }
  .detail-qty > a {
    font-size: 20px;
  }
  .detail-qty > a.qty-down {
    bottom: -34px !important;
    left: -36px;
    transform: rotate(90deg);
    color: black;
  } */
  .cart-totals {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    padding: 25px 14px !important;
  }
  .cart-totals.modal-order-summery {
    width: 90%;
  }
  .sm\:mt-0 {
    margin-top: 0 !important;
  }
  .heading_s1 h4 {
    font-size: 18px;
  }
  .heading_s1 p {
    font-size: 14px;
  }
  .payment_method {
    text-align: start;
  }
  .payment_method h5 {
    font-size: 18px;
  }
  .payment_option {
    width: 100%;
  }
  .sm\:mb-0 {
    margin-bottom: 0 !important;
  }
  .mobile_login {
    max-width: 100% !important;
    width: 100% !important;
    height: 100vh;
    display: flex;
  }
  .mobile_login .modal-content {
    border-radius: 0 !important;
  }
  .myDeal h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 1.1;
    margin-bottom: 5px;
    color: #fff;
  }
  .myDeal {
    background: var(--primary-colors);
    padding: 2rem;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5rem;
  }
  .myDeal-body .deal {
    border-radius: 25px;
    margin-top: 0px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-top: 40px !important;
    min-height: 400px !important;
  }
  .myDeal-body {
    position: absolute;
    top: 0;
  }

  .mobile_login .modal-content .termsOfUse {
    text-align: center !important;
  }
  .mobile_login h5 {
    font-size: 18px;
  }
  .widget-category-2 ul li a img {
    max-width: 40px;
    border-radius: 24px;
  }
  .widget-category-2 ul li a span {
    padding: 7px 12px 7px 0px;
  }
  .widget-category-2 ul li {
    border-radius: 24px;
    padding: 0;
  }
  .hero_wrapp {
    display: flex;
    flex-direction: column-reverse;
  }

  .carausel-10-columns-cover .carausel-10-columns .card-2 {
    padding: 0 !important;
    padding-top: 12px !important;
  }
  .home-slide-cover {
    margin-top: 10px !important;
  }
  .catrgory_title {
    font-weight: normal;
    font-size: 13px !important;
  }
  .card-2 figure img {
    width: 70px;
    height: 100%;
  }

  .product-grid-3 .col-6 {
    padding: 0 5px 0 5px;
  }
  .primary-sidebar {
    padding: 0;
  }
  .product-grid-3 h3 {
    font-size: 16px;
  }
  .product-name {
    margin-bottom: 10px;
  }
  .menu_footer {
    height: 155px;
    border-bottom: 0 !important;
    align-items: flex-start;
    /* flex-direction: column; */
  }
  .menu_footer_sub {
    width: 100%;
    border-top: 1px solid #ececed;
    padding-top: 0px;
  }
  .menu_footer_follow a svg {
    color: var(--primary-colors) !important;
    font-size: 16px;
  }
  .menu_footer_follow {
    margin-bottom: 15px;
    margin-top: 5px;
    align-items: center;
    justify-content: start;
    /* max-width: 150px; */
  }
  .menu_footer_follow a {
    margin: 0 5px;
  }
  .MobileNav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 50px;
    z-index: 9999;
    padding: 5px 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-around;
    transition: all ease-in-out 0.3s;
    /* box-shadow: rgb(149 157 165 / 20%) 0px -5px 24px; */
  }
  .MobileNav.hideToBottom {
    bottom: -60px;
  }
  .mobile_nav_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile_nav_item svg {
    font-size: 22px;
  }
  .mobile_nav_item span {
    font-size: 12px;
    line-height: 12px;
  }
  .mobile_nav_item .navIcon2 {
    top: -3px !important;
  }
  .mobile_total {
    margin-bottom: 30px;
  }
  .mobile_total_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile_total_item p {
    font-size: 14px;
    margin: 0;
  }
  .mobile_total .youSave {
    border-top: 1px solid #e9ecef;
    margin-top: 10px;
    padding: 10px 0;
  }

  .carausel-10-columns-cover .carausel-10-columns .card-2 {
    margin: 0;
  }
  .checkout_mob .panel-body {
    border: none;
    padding: 0;
  }
  .checkout_mob {
    padding: 0 10px;
  }
  .cart-totals,
  .order_details {
    border: none !important;
    /* padding: 0 !important; */
  }
  .order_table {
    overflow: hidden !important;
    max-height: 100% !important;
  }

  .order_table table .product-thumbnail img {
    padding: 0;
  }
  .cart-bottom-noti.show {
    bottom: 70px !important;
  }
  .cart-bottom-noti .btn {
    font-size: 10px;
    padding: 10px;
  }
  .flotingCart p {
    line-height: 17px;
    width: 85%;
  }
  .font-mainHead {
    font-size: 16px !important;
  }
  .cart-totals {
    margin-bottom: 0 !important;
    padding-bottom: 3rem !important;
  }
  .cart-totals.modal-order-summery {
    padding-bottom: 0rem !important;
  }
  .flotingCart {
    z-index: 9999 !important;
    /* justify-content: space-between !important; */
  }
  .flotingCart,
  .mobileFlotingBtn {
    position: fixed;
    bottom: 0;
    background: #fff;
    z-index: 999;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 10px;
    box-shadow: rgb(149 157 165 / 20%) 0px -5px 24px;
  }
  .sm\:font-size-10 {
    font-size: 10px !important;
  }
  .sm\:font-size-11 {
    font-size: 11px !important;
  }
  .sm\:m-auto {
    margin: auto !important;
  }
  .sm\:p-10 {
    padding: 10px !important;
  }
  .invoice-item {
    padding: 12px !important;
  }
  .invoice-center,
  .invoice .invoice-header,
  .invoice .invoice-bottom {
    padding: 12px;
  }
  .cartempty {
    font-size: 18px;
  }
  .cartemptySub {
    font-size: 14px;
  }
  .custom-modal .modal-dialog .modal-content {
    padding: 0 !important;
    flex-direction: column-reverse;
  }
  .deal {
    padding: 30px !important;
  }
  .notification_modal {
    z-index: 9999;
  }
  .otp_container div {
    margin-right: 3px;
  }
  .orderHead {
    font-size: 20px;
  }
  .account .card .card-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .account .card .card-header svg {
    margin-left: 0 !important;
  }
  .mobile_track {
    margin-bottom: 0;
  }
  .DeliveredAdd {
    font-size: 14px;
  }
  .myAddressNew {
    padding: 1.5rem;
  }
  .mobileEdit {
    padding: 0;
  }
  .mobileEdit .modal-dialog {
    width: 100% !important;
    height: 100%;
  }
  .mobileEdit .modal-dialog .modal-content {
    border-radius: 0;
    height: 100%;
  }
  .mobileEdit .deal-top {
    margin-top: 4rem;
    padding: 0 !important;
  }
  .mobileEdit .deal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .shoplist-child {
    min-height: 260px !important;
    max-height: 290px;
  }
  .main_image_slider {
    width: auto !important;
    height: auto !important;
  }
  .main_image_slider img {
    border-radius: 0 !important;
    object-fit: cover !important;
  }
  .detail-gallery .product-image-slider {
    border-radius: 0 !important;
  }
  .product-extra-link2 .button.button-add-to-cart {
    height: 40px;
    line-height: 40px;
    font-size: 13px !important;
    padding: 0 5px !important;
    min-width: 100px;
  }
  .sm\:font-14 {
    font-size: 14px;
  }
  .image.product-thumbnail {
    padding-left: 0;
  }
  .dashboard-menu ul li a {
    font-size: 14px;
    padding: 10px 20px;
    font-weight: 500;
  }
  .dashboard-menu ul li {
    height: 45px;
    border-radius: 5px;
  }
  .dashboard-menu ul li a.active {
    border-radius: 5px;
  }
  .mobileCat {
    margin-bottom: 5px !important;
  }
  .mobileCat.pages {
    margin-bottom: 20px !important;
  }
  .mobileCat img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
  }

  .mobileCat .entry-content-2 {
    padding: 5px;
  }
  .mobileCat .entry-content-2 h6 {
    margin-bottom: 0 !important;
  }
  .sm\:max-w-47 {
    max-width: 47%;
  }
  .isMoblieCate .react-loading-skeleton {
    border-radius: 50% !important;
    height: 70px !important;
    width: 70px !important;
  }

  .mainNotification {
    padding: 0 !important;
  }
  .footer_main {
    margin-bottom: 90px !important;
  }
  .single-header h2 {
    font-size: 18px;
  }
  .single-header .entry-meta.meta-1 {
    margin-top: 0 !important;
  }
  .useDashboard h3 {
    font-size: 18px;
  }
  .useDashboard .card-body {
    padding-top: 0;
  }
  .sm\:font-size-18 {
    font-size: 18px !important;
  }
  .sm\:font-size-16 {
    font-size: 16px !important;
  }
  .sm\:font-size-14 {
    font-size: 14px !important;
  }
  .myAddress p {
    font-size: 14px;
  }
  .mobileOrderCard p {
    font-size: 14px;
  }
  .section-title.style-2 h3 {
    font-size: 16px !important;
    margin-bottom: 10px !important;
  }
  .allCat {
    align-items: flex-end !important;
    justify-content: center;
    display: flex;
  }
  .allCat img {
    border-radius: 0% !important;
    width: 60px !important;
  }
  .home-slider .hero-slider-1,
  .hero-slider-1 .single-hero-slider {
    height: 155px !important;
  }
  .hero-slider-1 .single-hero-slider {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px !important;
  }
  .mobileBanner {
    width: 100%;
    /* min-height: 155px !important; */
    margin-bottom: 10px !important;
    border-radius: 5px !important;
  }
  .product-cart-wrap .product-img-action-wrap .product-img img {
    object-fit: contain;
    height: 230px !important;
  }
  .product-cart-wrap .product-img-action-wrap .product-img {
    height: 230px !important;
    border-radius: 5px;
  }
  .section-padding {
    padding: 0px 0 !important;
  }
  .mobileContainer {
    height: 200px;
    border-radius: 5px !important;
  }
  .mobileCollection {
    padding: 12px !important;
  }
  .mobileCollection article {
    height: 200px !important;
    margin-bottom: 15px !important;
  }
  .swiper-container {
    margin-top: 0 !important;
  }
  .product-cart-wrap .product-img-action-wrap .product-action-1 {
    min-width: fit-content !important;
  }
  .mobilePadding:nth-child(odd) {
    padding: 0 5px 0 10px;
  }
  .mobilePadding:nth-child(even) {
    padding: 0 10px 0 5px;
  }
  .product-grid-4 .product-cart-wrap {
    margin-bottom: 10px !important;
    min-height: 320px !important;
    height: 290px;
  }
  .product-cart-wrap {
    border-radius: 5px !important;
  }
  .sm\:mb-10px {
    margin-bottom: 10px !important;
  }
  .group_wrapper {
    margin: 20px 0;
  }
  /* .group_wrapper:nth-child(3) {
    margin-top: 20px !important;
  } */
  .mobileListCard {
    margin: 0 !important;
  }
  .product-list-small {
    /* border-bottom: 1px solid #f3f6fa; */
    margin: 2px;
  }
  .product-list-small figure img {
    border-radius: 5px;
  }
  .product-list-small figure.col-md-4 {
    max-width: 25%;
  }
  .carausel-4-columns-cover .product-cart-wrap {
    margin-bottom: 0 !important;
  }
  .productFive .mobilePadding:nth-child(odd) {
    padding: 0 10px 0 5px;
  }
  .productFive .mobilePadding:nth-child(even) {
    padding: 0 5px 0 10px;
  }
  .product-cart-wrap .product-action-1 {
    opacity: 1;
    visibility: visible;
    left: 90%;
    top: 7%;
    background-color: transparent !important;
    border: none !important;
  }
  .product-cart-wrap .product-action-1 svg {
    color: #fff !important;
    font-size: 1.3rem;
  }
  .related-products .shoplist-child {
    min-height: 309px !important;
  }
  .emptyCart {
    margin-top: 4rem !important;
  }
  .invoice {
    padding-top: 0;
  }
  .invoice .invoice-item {
    padding: 0 !important;
  }
  .totalPrice {
    font-size: 24px;
  }
  .Taxes {
    font-size: 13px;
  }
  .invoice-info .head {
    font-size: 23px;
  }
  .newCollBanner img {
    height: 220px;
  }
  .newCollBanner-col:nth-child(odd) {
    padding: 0 5px 0 10px;
  }
  .newCollBanner-col:nth-child(even) {
    padding: 0 10px 0 5px;
  }
  .newCollBanner-style {
    padding-bottom: 10px !important;
    border-radius: 0 !important;
  }
  .sm\:mb-1 {
    margin-bottom: 10px !important;
  }
  .mobileSubCat {
    padding: 2px 10px !important;
  }
}

@media screen and (max-width: 380px) {
  .bannerFooterImg {
    height: 160px;
  }
  .product-cart-wrap .product-img-action-wrap .product-img {
    /* height: 180px !important; */
  }
}
@media screen and (max-width: 320px) {
  .bannerFooterImg {
    height: 135px;
  }
  .product-cart-wrap .product-img-action-wrap .product-img {
    /* height: 145px !important; */
  }
  .product-grid-4 .product-cart-wrap {
    margin-bottom: 10px !important;
    min-height: 280px !important;
    height: 280px;
  }
}

/* //chat */
.scroll-to-top {
  position: fixed;
  right: 60px;
  bottom: 80px;
  width: 120px;
  font-size: 14px;
  line-height: 44px;
  border-radius: 50%;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  transition: all 300ms ease;
}

.scroll-to-top img {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
.blink {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.9;
    transform: scale(1.05);
  }
}
.w_button {
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  border-radius: 20px !important;
}

.w_button img {
  width: 50px;
  height: 50px;
  margin-left: -17px;
}
.fixed_btn {
  position: fixed;
  width: fit-content;
  bottom: 6rem;
  right: 0rem;
}
.w_button span {
  font-size: 20px !important;
  text-transform: capitalize !important;
}
.scroll-to-top {
  opacity: 0;
  visibility: hidden;
}
.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  transition: ease-in-out 0.3s;
}
.h-calc {
  height: calc(100vh - 150px) !important
  ;
}

.comments-area p.text-pass {
  font-size: 22px !important;
  height: 13px;
}
